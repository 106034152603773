.hotelName {
  width: fit-content;
  float: right;
  font-family: RalewayBold;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.4px;
  color: #8b9eb0;
  font-size: 20px;
  position: absolute;
  right: 30px;
  top: 30px;
}
