@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@font-face {
    font-family: 'RalewayBold';
    src: local('RalewayBold'), url("../fonts/Raleway-Bold.ttf") format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'RalewayRegular';
        src: local('RalewayRegular'), url("../fonts/Raleway-Regular.ttf") format('truetype');
        font-display: swap;
}
@font-face {
    font-family: 'RalewayThin';
    src: local('RalewayThin'), url("../fonts/Raleway-Thin.ttf") format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'RalewayLight';
        src: local('RalewayLight'), url("../fonts/Raleway-Light.ttf") format('truetype');
        font-display: swap;
}
@font-face {
    font-family: 'RalewaySemiBold';
        src: local('RalewaySemiBold'), url("../fonts/Raleway-SemiBold.ttf") format('truetype');
        font-display: swap;
}
@font-face {
    font-family: 'RalewayMedium';
        src: local('RalewayMedium'), url("../fonts/Raleway-Medium.ttf") format('truetype');
        font-display: swap;
}
@font-face {
    font-family: 'RalewayHeavy';
        src: local('RalewayHeavy'), url("../fonts/Raleway-Heavy.ttf") format('truetype');
        font-display: swap;
}
@font-face {
    font-family: 'RalewayExtraBold';
        src: local('RalewayExtraBold'), url("../fonts/Raleway-ExtraBold.ttf") format('truetype');
        font-display: swap;
}